<template>
    <div class="modal" :class="{'is-active': isActive}">
        <div class="modal-background" @click="dismiss"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Change Background</p>
                <button class="delete" aria-label="close" @click="dismiss"></button>
            </header>
            <section class="modal-card-body">
                <div class="field">
                    <div class="control">
                        <div class="field switch">
                            <label class="label" for="bgType">Image</label>
                            <input 
                                id="bgType" 
                                type="checkbox" 
                                name="type" 
                                class="switch is-rounded is-outlined" 
                                v-model="type"
                                :checked="bgType === 'colours'"
                                @change="valChanged('type')"
                            />
                            <label class="label" for="bgType">Colours</label>
                        </div>
                    </div>
                </div>
                <div class="type-settings type-image">
                    <div class="settings-left has-preview">
                        <div class="image-preview cover-image" :style="imagePreview">
                            <div class="overlay" :style="overlayStyle"></div>
                        </div>
                        <div v-if="modelValue.type ==='image'" class="button is-small is-rounded is-info" @click="triggerUpload">
                            Upload your own image
                        </div>
                    </div>
                    <div v-if="modelValue.type === 'image' && modelValue.overlay" class="settings-right">
                        <div class="field">
                            <label class="label is-small">Overlay</label>
                            <div class="control">
                                <div class="select is-small is-rounded">
                                    <select :value="modelValue.overlay.type" @change="valChanged('overlay.type', $event)">
                                        <option 
                                            v-for="(ot, ind) in overlayTypes"
                                            :key="'ovrly-' + ind"
                                            :value="ot.val"
                                            :selected="modelValue.overlay.type === ot.val"
                                        >
                                            {{ ot.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="modelValue.overlay.type === 'custom'" class="custom-settings">
                            <div class="field">
                                <label class="label is-small">Colour</label>
                                <div class="control">
                                    <input 
                                        class="input is-small is-rounded color" 
                                        type="color" 
                                        :value="overlay.colours[0].colour" 
                                        @input="valChanged('overlay.colours.0.colour', $event)"
                                    />
                                </div>
                            </div>
                            <div class="field">
                                <label class="label is-small">{{ modelValue.overlay.opacity }}% Opacity</label>
                                <input 
                                    class="slider is-fullwidth is-small is-circle" 
                                    step="1" min="0" max="100" type="range" 
                                    :value="modelValue.overlay.opacity"
                                    @input="valChanged('overlay.opacity', $event)"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-if="modelValue.type === 'colours' && modelValue.overlay" class="settings-right">
                        <div v-for="(c, i) in overlay.colours" 
                            :key="'colour-' + i"
                            class="field"
                        >
                            <label class="label is-small">Colour {{ i + 1 }}</label>
                            <div class="field-inner">
                                <div 
                                    v-if="modelValue.overlay.colours.length < 8" 
                                    class="fake-button is-small is-rounded"
                                    @click="addColour(i)"
                                >
                                    <i class="fas fa-plus-circle"></i>
                                </div>
                                <div class="control">
                                    <input 
                                        class="input is-small is-rounded color" 
                                        type="color" 
                                        :value="modelValue.overlay.colours[i].colour" 
                                        @input="valChanged(`overlay.colours.` + i + '.colour', $event)"
                                    />
                                </div>
                                <div 
                                    class="fake-button is-small is-rounded"
                                    @click="removeColour(i)"
                                >
                                    <i class="fas fa-trash"></i>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label is-small">{{ modelValue.overlay.opacity }}% Opacity</label>
                            <input 
                                class="slider is-fullwidth is-small is-circle" 
                                step="1" min="0" max="100" type="range" 
                                :value="modelValue.overlay.opacity"
                                @input="valChanged('overlay.opacity', $event)"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot has-text-centered">
                <button class="button is-success is-small is-rounded" @click="save">Save</button>
                <button class="button is-small is-rounded" @click="dismiss">Cancel</button>
            </footer>
        </div>
    </div>
</template>

<script>
import { each, set } from "lodash-es";

export default {
    name: "BackgroundPicker",
    props: {
        active: Boolean,
        modelValue: Object,
        imagePreview: Object
    },
    data: function() {
        return {
            isActive: this.active,
            type: false,
            overlayTypes: [
                {
                    name: "No overlay",
                    val: "none"
                },
                {
                    name: "White 30%",
                    val: "white-30"
                },
                {
                    name: "Black 30%",
                    val: "black-30"
                },
                {
                    name: "Custom",
                    val: "custom"
                }
            ],
            background: {
                overlay: {}
            }
        }
    },
    computed: {
        overlay: function() {
            if(!this.modelValue || !this.modelValue.overlay) return null;

            var overlay = this.modelValue.overlay;

            switch(overlay.type) {
                case "custom":
                    if(!overlay.colours) overlay.colours = [{ colour: "#000000"}];
                    break;
            }

            return overlay;
        },
        overlayStyle: function() {
            if(!this.overlay) return {
                colours: []
            };

            var ovrly = {
                show: true,
                backgroundColor: null,
                opacity: (this.overlay.opacity)/100,
            }

            if(this.modelValue.type === "image") {
                switch(this.overlay.type) {
                    case "none":
                        ovrly.show = false;
                        break;

                    case "white-30":
                        ovrly.backgroundColor = "white";
                        ovrly.opacity = 0.3
                        break;


                    case "black-30":
                        ovrly.backgroundColor = "black";
                        ovrly.opacity = 0.3
                        break;

                    case "custom":
                        ovrly.backgroundColor = this.overlay.colours && this.overlay.colours[0] ? this.overlay.colours[0].colour : "black";
                        ovrly.opacity = (this.overlay.opacity)/100;
                        break;
                }
            } else {
                ovrly.backgroundColor = this.overlay.colours && this.overlay.colours[0] ? this.overlay.colours[0].colour : null;

                var gradString = ["180deg"];

                each(this.overlay.colours, (c, i) => {
                    gradString.push(c.colour + " " + ((100/this.overlay.colours.length) * i) + '%');
                })

                gradString = gradString.join(", ");

                ovrly.background = `linear-gradient(${gradString})`
            }

            
            return ovrly;

        },
        bgType: function() {
            if(!this.modelValue) return null;
            return this.modelValue.type;
        }
    },
    methods: {
        save: function() {
            this.isActive = false;
            this.$emit("update:modelValue", this.background);
            this.$emit("save")
        },
        dismiss: function() {
            this.isActive = false;
             this.$emit("dismissed");
        },
        show: function() {
            this.isActive = true;
        },
        valChanged: function(name, e) {

            this.background = this.modelValue;

            switch(name)  {
                case "type":
                    //console.log(this.type);
                    this.background.type = this.type ? "colours" : "image";
                    if(this.background.type === "colours") {
                        if(!this.background.colours) this.background.colours = [];
                        this.background.colours[0] = { colour: "#000000", position: 0};
                        this.background.overlay.show = true;
                        this.background.overlay.opacity = 1;
                    }
                    break;

                case "overlay.type":
                    //console.log(e.target.value);
                    this.background.overlay.type = e.target.value;
                    break;

                case "overlay.opacity":
                    this.background.overlay.opacity = e.target.value;
                    break;

                case "overlay.colours.0":
                    this.background.overlay.colours[0] = { 
                        colour: e.target.value,
                        position: 0
                    };
                    break;

                default: 
                    if(name.includes("overlay.colours.")) {
                        set(this.background, name, e.target.value);
                    }
                    break;
            }

            //this.$emit("input", this.background);
            
        },
        addColour: function(i) {
            var colour = {
                colour: "#000000",
                position: 0
            }
            if(i > 0) {
                colour.colour = this.overlay.colours[i - 1].colour;
                colour.position = this.overlay.colours[i - 1].position + 10;
                if(colour.position > 100) colour.position = 100;
            } 

            this.background.overlay.colours.splice(i, 0, colour);
            //this.$emit("input", this.background);
            
        },
        removeColour: function(i) {
            this.background.overlay.colours.splice(i, 1);
            //this.$emit("input", this.background);
        },
        triggerUpload: function() {
            this.$emit("triggerUpload");
        }
    },
    mounted: function() {
        window.bgp = this;
    },
    watch: {
        bgType: {
            immediate: true,
            handler: function(newVal) {
                if(this.type === false) this.type = newVal === "colours";
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        .modal-card {
            width: 90vw;
            max-width: 380px;
            min-width: 300px;

            .modal-card-head, .modal-card-foot {
                padding: 8px;
                
                p.modal-card-title {
                    font-size: 1rem;
                    font-weight: bold;
                }
            }

            .modal-card-foot {
                justify-content: center;
            }

            .field.switch {
                display: flex;
                align-items: center;
                justify-content: center;

                input.switch {
                    margin: 0px 5px;
                }

                label.label {
                    margin-bottom: 0px;

                    &:first-child {
                        margin-right: 5px;
                    }
                }
            }

            .type-settings {
                display: flex;
                margin-top: 20px;
                align-items: stretch;
                justify-content: center;

                .settings-left, .settings-right {
                    display: flex;
                    align-items: center;
                    //justify-content: center;
                    flex-direction: column;

                    .image-preview {
                        margin-bottom: 10px;
                    }

                    .field.switch {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .settings-left.has-preview {
                    min-width: 180px;
                    position: relative;
                }

                .settings-right {
                    padding-left: 20px;
                    max-width: 120px;
                }


            }

            .image-preview {
                min-width: 150px;
                width: 40%;
                max-width: 200px;
                height: 320px;
                border-radius: 20px;
                border: solid 3px rgba(black, 0.6);
                position: relative;
                overflow: hidden;
                z-index: 5;

                .overlay {
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    left: 0px;
                    position: absolute;
                    background-color: transparent;
                    //border-radius: 20px;
                }

            }
        }

        .field-inner {
            display: flex;
            align-items: center;
            justify-content: center;

            .fake-button {
                font-size: 0.9rem;
                margin-left: 10px;
                margin-right: 10px;
            }

        }

        input.input.color {
            padding: 0px 0px;
            line-height: unset;
            outline: none;
            box-shadow: none;
            border-radius: 40px;
            border: transparent;
            overflow: hidden;
            width: 33px;
            height: 30px;
        }
    }
</style>