<template>
    <div class="file-upload-wrapper">
        <div class="file-upload-inner" 
            :class="[{'cover-image': preview, 'has-file': eitherFile, 'is-uploading': uploading}, position]" 
            :style="{ bgStyle }"
        >
            <div class="upload-overlay" @click="triggerUpload()" :class="{'invisible': invisible}">
                <div v-if="oldFile" class="action-icon">
                    <i class="fas fa-pen"></i>
                </div>
                <div v-else class="action-icon">
                    <i class="fas fa-cloud-upload-alt"></i>
                </div>
                <input type="file" name="file" class="no-show" ref="fileInput" accept="image/*" @change="doFileUpload($event)" />
                <!-- <div id="progress">
                    <div id="progressInner"></div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { doc, setDoc } from "firebase/firestore";
// eslint-disable-next-line no-unused-vars
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default {
    name: "FileUpload",
    components: {
        
    },
    props: {
        filepath: String,
        filename: String,
        oldFile: Object,
        preview: Boolean,
        tag: String,
        position: String,
        invisible: Boolean
    },
    data() {
        return {
            uploading: false,
            newFileRef: null,
            storage: null,
            progress: 0,
            downloadURL: null,
            file: null,
            ref: null,
            showCropper: false
        }
    },
    computed: {
        ...mapGetters({
            user: "user/getUser",
            userRef: "user/getUserRef",
            //storage: "auth/getStorage",
            db: "auth/getDB",
            fs: "auth/getFS"
        }),
        name: function() {
            if(this.filename) return this.filename;
            return this.oldFile ? this.oldFile.name : this.newFileRef ? this.newFileRef.id : "tempfilename";
        },
        path: function() {
            if(this.filepath) return this.filepath;
            if(!this.newFileRef) return null;
            if(!this.user) return "unknown_user/files/";
            return "users/" + this.user.uid + "/files/" + this.newFileRef.id + "/original." + this.ext;
        },
        eitherFile: function() {
            return this.newFile || this.oldFile;
        },
        bgStyle: function() {
            if(!this.preview) return false;
            if(this.eitherFile) return { backgroundImage: this.eitherFile.thumbs.small };
            return null;
        },
        type: function() {
            var f = this.file || this.newFile || this.oldFile;
            if(!f) return null;
            return f.type;
        },
        ext: function() {
            if(!this.type) return null;
            var extn = null;

            switch(this.type) {
                case "image/jpeg":
                case "image/jpg":
                    extn = "jpg";
                    break;

                case "image/png":
                    extn = "png";
                    break;
            }

            return extn;
        },
        newFile: function() {
            if(!this.newFileRef) return null;
            return {
                user: this.userRef,
                id: this.newFileRef.id,
                type: this.type,
                ext: this.ext,
                url: this.downloadURL,
                path: this.path
            }
        }
    },
    methods: {
        ...mapActions({
            setUpload: "layout/setUpload",
        }),
        triggerUpload: function() {
            this.$refs.fileInput.click();
        },
        doFileUpload: function(e) {
            if(!e || !e.target || !e.target.files || !e.target.files[0]) return null;
            this.file = e.target.files[0];
            this.showCropper = true;
            //return console.log(file);
            // eslint-disable-next-line no-unreachable
            this.ref = ref(this.storage, this.path);
            var uploadTask = uploadBytesResumable(this.ref, this.file);
            this.progress = 0;
            this.uploading = true;
            this.setUpload({"upload.ing": true, "upload.progress": 0});
            uploadTask.on("state_changed", snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var p = (snapshot.bytesTransferred / snapshot.totalBytes);
                this.progress = p * 100;
                this.setUpload({"upload.ing": true, "upload.progress": p});
                console.log('Upload is ' + this.progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            })

            // eslint-disable-next-line no-unreachable
            uploadTask.then(snapshot => {
                console.log("file uploaded");
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    this.downloadURL = downloadURL;
                    console.log('File available at', downloadURL);
                    setDoc(this.newFileRef, this.newFile)
                    .then(() => {
                        this.$emit("uploaded", {tag: this.tag, ref: this.newFileRef, newFile: this.newFile});
                        setTimeout(() => { 
                            this.setUpload({"upload.ing": false, "upload.progress": 0});
                            this.refresh();

                        }, 1000);
                        
                    })
                    .catch(err => {
                        console.error(err);
                        this.setUpload({"upload.ing": false, "upload.progress": 0});
                    })
                });
            })
        },
        refresh: function() {
            this.setUpload({"upload.progress": 0});
            this.file = null;
            this.progress = 0;
            this.downloadURL = null;
            if(!this.filename) this.newFileRef = doc(this.db.files);
            this.storage = getStorage();
            this.uploading = false;
        },
        setup: function() {
            if(!this.filename) this.newFileRef = doc(this.db.files);
            this.storage = getStorage();
        }
    },
    mounted: function() {
        this.setup();
        window.fileupload = this;
    }
}
</script>

<style lang="scss" scoped>
    .file-upload-wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        
        .file-upload-inner {
            display: flex;
            height: 100%;
            width: 100%;
            position: relative;
            cursor: pointer;

            .upload-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: 50%;
                background-color: rgba(black, 0.7);
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: opacity 0.3s ease;

                input.no-show {
                    opacity: 0;
                    width: 0px;
                    height: 0px;
                    overflow: hidden;
                }

                &.invisible {
                    background-color: transparent;
                    
                    .action-icon {
                        display: none;
                    }
                }
                
            }

            &.topright {
                .upload-overlay {
                    right: 10px;
                    top: 10px;
                    bottom: unset;
                    left: unset;
                    opacity: 1;
                    padding: 5px;
                    width: 2rem;
                    height: 2rem;
                    font-size: 1rem;
                }
            }

            &.bottomright {
                .upload-overlay {
                    right: 10px;
                    bottom: 10px;
                    top: unset;
                    left: unset;
                    opacity: 1;
                    padding: 5px;
                    width: 2rem;
                    height: 2rem;
                    font-size: 1rem;
                }
            }

            &:hover {
                .upload-overlay {
                    opacity: 1;
                }
            }

            &.is-uploading {
                .upload-overlay {
                    opacity: 1;

                    .action-icon {
                        display: none;
                    }

                    #progress {
                        opacity: 1;
                    }
                }
            }

        }
    }
</style>