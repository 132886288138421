<template>
    <div class="row" :class="{'not-draggable editing': editing, 'fade-out': unfocus }" :draggable="!editing"> 
        <div class="handle">
            <i class="fas fa-ellipsis-v"></i>
        </div>
        <div class="row-inner">
            <PageElement 
                v-for="(elem, elemIndex) in elements" 
                :key="'elem-' + elemIndex" 
                v-model="elements[elemIndex]"
                :editingElement="editing"
                @save="elementUpdated" 
                @editing="setEditing"
                @deleted="elementDeleted"
            />
        </div>
        <div v-if="debug" class="show-id">
            {{ row.id.substring(0, 6) }}
        </div>
        <div v-if="unfocus" class="unfocus-overlay">
        </div>
    </div>
</template>

<script>
import PageElement from "@/components/PageElement.vue";
import { remove } from "lodash-es";

export default {
    name: "Row",
    data: function() {
        return {
            //row: this.modelValue,
            editing: false,
            debug: false
        }
    },
    props: {
        modelValue: Object,
        rowEditing: Boolean,
        rowIndex: Number
    },
    components: {
        PageElement
    },
    computed: {
        unfocus: function() {
            return this.rowEditing && !this.editing
        },
        row: {
            get() {
                if(this.debug) console.log("row: getting row");
                return this.modelValue;
            },
            set(value) {
                if(this.debug) console.log("row: setting row");
                return this.$emit("update:modelValue", value)
            }
        },
        elements: {
            get() {
                if(this.debug) console.log("row: getting elements");
                if(!this.row || !this.row.elements) return [];
                return this.row.elements;
            },
            set(value) {
                if(this.debug) console.log("row: setting elements");
                this.row.elements = value;
            }
        }
    },
    methods: {
        elementUpdated: function() {
            if(this.debug) console.log("elementUpdated", this.row);
            this.$emit("update:modelValue", this.row);
            //this.$emit("elementUpdated", { ...payload, row: this.row});
        },
        donothing: function(e) {
            if(this.debug) console.log(e);
        },
        setEditing: function(editing) {
            this.editing = editing;
            this.$emit("editing", editing);
        },
        elementDeleted: function(elemId) {
            if(this.debug) console.log("deleting element", elemId);
            var els = this.elements;
            remove(els, e => { return e.id === elemId });
            this.elements = els;
            if(this.elements.length === 0) {
                this.setEditing(false);
                this.$emit("deleted", this.row.id);
            }
        }
    },
    // watch: {
    //     modelValue: {
    //         immediate: true,
    //         deep: true,
    //         handler: function(newValue) {
    //             if(newValue) this.row = newValue;
    //         }
    //     }
    // }
}
</script>

<style lang="scss" scoped>
$darkColor: rgb(48, 48, 48);
$lightColor: rgba($darkColor, 0.5);

.row {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: transparent;
    transition: all 0.3s ease;
    //margin: 3px 0px;
    z-index: 1;
    //background-color: rgba(black, 0.05);

    &.editing {
        z-index: 2;
    }

    .handle {
        opacity: 0;
        position: absolute;
        left: 10px;
        cursor: move;
        transition-delay: all 0.3s ease;
        color: rgba(white, 0.4);
        z-index: 10;
        top: 0px;
        width: 25px;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($lightColor, 0.3);
        border-radius: 3px;
        z-index: 2;
    }

    .row-inner {
        width: 80%;
        position: relative;
        margin: auto;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover, &.hover {
            //background-color: rgba(black, 0.15);
            //box-shadow: 0px 0px 10px 0px rgba(white, 0.3);
            .row-inner {
                //border-radius: 3px;
                //box-shadow: inset 0px 0px 0px 3px $lightColor;
            }

            .handle {
                opacity: 1;
            }
        }

        &.editing {
            &:hover {
                .row-inner {
                    box-shadow: none;
                }
            }
        }
    }

    @media (hover: none) {
        .handle {
            opacity: 1;
            width: 40px;
            left: 10px;
            top: 2px;
            bottom: 2px;
            background-color: rgba($lightColor, 0.2);
        }
    }

    &.not-draggable {
        .handle {
            display: none !important;
        }
    }

    &.fade-out {
        opacity: 0.3;
        cursor: not-allowed;
        filter: blur(3px);

        .handle {
            display: none;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover, &.hover {
                //background-color: rgba(black, 0.15);
                //box-shadow: 0px 0px 10px 0px rgba(white, 0.3);
                    border-radius: 3px;
                    box-shadow: none;
                

                .handle {
                    opacity: 0;
                }
            }
        }

        @media (hover: none) {
            .handle {
                opacity: 0;
            }
        }

        .unfocus-overlay {
            position: absolute;
            top: 0;
            right: 0;
            left: -20px;
            bottom: 0;
            //background-color: rgba(black, 0.1);
            cursor: not-allowed;
            z-index: 1;
        }
    }

    .show-id {
        position: absolute;
        bottom: 0px;
        font-size: 0.7rem;
        text-align: center;
        left: 0px;
        right: 0px;
    }
}
</style>