<template>
    <div class="editor wrapper is-vertical is-fullpage is-vcentered" :class="previewClass">
        <div v-if="page" class="page-outer">
            <div v-if="page.design && page.design.hero" class="hero-image">
            </div>
            <div class="page-inner fake-mobile-width cover-image" :style="pageBG">
                <div class="overlay" :style="overlayStyle"></div>
                <div 
                    v-if="page.design.banner && page.design.banner.show" 
                    class="banner-pic cover-image" 
                    :class="{'no-banner-pic': !bannerPic}"
                    :style="bannerPicBG"
                >
                    <FileUpload v-if="page.design.banner.show" 
                        :oldFile="bannerPic" 
                        tag="bannerPic" 
                        @uploaded="fileUploaded" 
                        position="topright"
                    />
                </div>
                <div class="profile-pic cover-image" :class="{'smaller-profile-pic no-profile-pic': !profilePic}" :style="profilePicBG">
                    <FileUpload ref="profilePicUploader" :oldFile="profilePic" tag="profilePic" @uploaded="fileUploaded" />
                </div>
                <PageElement 
                    class="handle"
                    ref="handleElement"
                    :solo="true"
                    :modelValue="handle"
                    :editingElement="editing"
                    @update="elementUpdated" 
                    @editing="setEditing"
                    @resetVal="resetVal('handle')"
                />
                <Rows 
                    v-if="page.design.rows" 
                    id="rows" 
                    class="rows" 
                    ref="rows" 
                    v-model="rows" 
                    @editing="setEditingFromRows"
                    @save="savePage"
                />
                <FileUpload 
                    tag="backgroundPic" 
                    @uploaded="fileUploaded" 
                    ref="bgUploader" 
                    position="bottomright" 
                    :invisible="true"
                />
                <BackgroundPicker 
                    :active="modal === 'bgPicker'" 
                    ref="bgPicker" 
                    :imagePreview="pageBG"
                    v-model="page.design.background" 
                    @triggerUpload="triggerBGUpload"
                    @save="savePage"
                />
            </div>
        </div>
        <gCropper v-if="false" />
        <!-- <div v-if="working" class="working-overlay">
            <div class="lds-ellipsis light"><div></div><div></div><div></div><div></div></div>
        </div> -->
    </div>
    <Navbar class="is-fixed-bottom" @buttonClicked="handleEditClick" />
</template>

<script>
import BackgroundPicker from "@/components/BackgroundPicker.vue";
import FileUpload from "@/components/FileUpload.vue";
import Navbar from "@/components/Navbar.vue";
import PageElement from "@/components/PageElement.vue";
import { mapGetters, mapActions } from "vuex";
import Rows from "@/components/Rows.vue";
//import Row from "@/components/Row.vue";
import { orderBy, each, isArray, get, filter, remove, uniqBy } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import gCropper from "@/components/gCropper.vue";

export default {
    name: "Editor",
    components: {
        Navbar,
        FileUpload,
        BackgroundPicker,
        Rows,
        PageElement,
        gCropper
    },
    data() {
        return {
            page: null,
            working: true,
            gravatar: null,
            setup_done: false,
            sortable: null,
            modal: null,
            editing: false,
            debug: false
        }
    },
    computed: {
        ...mapGetters({
            getPage: "user/getPage",
            getUser: "user/getUser",
            currentBG: "layout/getCurrentBG"
        }),
        user: function() {
            return this.getUser;
        },
        savedPage: function() {
            return this.getPage({index: 0});
        },
        handle: function() {
            var el = {
                type: "handle",
                name: "handle",
                style: {
                    theme: null,
                    background: {
                        color: "transparent",
                    },
                    text: {
                        italic: null,
                        bold: null,
                        color: "#000"
                    },
                    classes: []
                },
                content: {
                    text: this.page ? this.page.handle : "nohandleset"
                }
            }

            return el;
        },
        profilePic: function() {
            if(!this.page || !this.user) return null;
            if(this.page && this.page.profile_pic) return this.page.profile_pic;
            if(this.user && this.user.profile_pic) return this.user.profile_pic;
            return null;
        },
        profilePicBG: function() {
            var pic = this.profilePic ? this.profilePic.url : null;
            if(!pic) pic = this.gravatar;
            return { backgroundImage: `url(${pic})`};
        },
        bannerPic: function() {
            if(!this.page || !this.user) return null;
            if(this.page && this.page.design.banner) return this.page.design.banner.file;
            return null;
        },
        bannerPicBG: function() {
            var pic = this.bannerPic ? this.bannerPic.url : null;
            return { backgroundImage: `url(${pic})`};
        },
        rows: {
            get() {
                if(!this.page || !this.page.design || !this.page.design.rows) return null;
                if(this.debug) console.log("editor: getting rows");
                var withEls = filter(this.page.design.rows, r => { return r.elements && r.elements.length > 0});
                var r = uniqBy(withEls, "id");
                r = orderBy(r, "order");
                if(this.debug) console.log(this.rowsString(r));
                return r;
            },
            set(value) {
                if(this.debug) console.log("editor: setting rows");
                if(this.debug) console.log(this.rowsString(value));
                each(value, (val, i) => {
                    if(val.elements && val.elements.length > 0) this.page.design.rows[i] = val;
                })
                if(this.debug) console.log(this.rowsString(this.page.design.rows));
            }
        },
        preview: function() {
            if(!this.$route.meta || !this.$route.meta.preview) return null;
            return this.$route.meta.preview;
        },
        previewClass: function() {
            if(!this.preview) return null;
            return "preview-" + this.preview;
        },
        background: function() {
            var bg = get(this.page, "design.background", null);

            return bg;
        },
        backgroundPic: function() {
            if(!this.background) return null;
            return this.background.image ? this.background.image.url : this.currentBG;
        },
        pageBG: function() {
            var bg = this.backgroundPic;
            if(!bg) bg = this.currentBG;

            var bgs = { 
                backgroundImage: `url(${bg})`
            }

            if(!this.background) return bgs;

            switch(this.background.type) {
                case "image":
                    return bgs;

                case "colours":
                    bgs.backgroundColor = this.background.colours[0];
                    bgs.backgroundImage = null;
                    break;
            }

            return bgs;
            
        },
        overlay: function() {
            var ov = get(this.page, "design.background.overlay",null )

            switch(ov.type) {
                case "custom":
                    if(!ov.colours) ov.colours = [{ colour: "#000000"}];
                    break;
            }

            return ov;
        },
        overlayStyle: function() {
            if(!this.overlay) return {
                colours: []
            };

            var ovrly = {
                show: true,
                backgroundColor: null,
                opacity: (this.overlay.opacity)/100
            }

            if(this.background.type === "image") {
                switch(this.overlay.type) {
                    case "none":
                        ovrly.show = false;
                        break;

                    case "white-30":
                        ovrly.backgroundColor = "white";
                        ovrly.opacity = 0.3
                        break;


                    case "black-30":
                        ovrly.backgroundColor = "black";
                        ovrly.opacity = 0.3
                        break;

                    case "custom":
                        ovrly.backgroundColor = this.overlay.colours && this.overlay.colours[0] ? this.overlay.colours[0].colour : "black";
                        ovrly.opacity = (this.overlay.opacity)/100;
                        break;
                }
            } else {
                ovrly.backgroundColor = this.overlay.colours && this.overlay.colours[0] ? this.overlay.colours[0].colour : null;

                var gradString = ["180deg"];

                each(this.overlay.colours, (c, i) => {
                    gradString.push(c.colour + " " + ((100/this.overlay.colours.length) * i) + '%');
                })

                gradString = gradString.join(", ");

                ovrly.background = `linear-gradient(${gradString})`
            }
            return ovrly;

        }
    },
    methods: {
        ...mapActions({
            doLoadPage: "user/loadPage",
            doSavePage: "user/savePage",
            setBackground: "layout/setBackground",
            doUpdatePageHandle: "user/updatePageHandle"
        }),
        setup: function() {
            this.setBackground({ "background.blurred": true });
            this.gravatar = "https://anonymous-animals.herokuapp.com/animal/Kangaroo";
            if(this.user && this.user.email) {
                this.gravatar = "https://anonymous-animals.herokuapp.com/animal/Kangaroo"; //gravatar.url(this.user.email, { protocol: "https"});
            }
            this.loadPage();
            this.setup_done = true;
        },
        fixPageVars: function() {
            if(this.debug) console.log("fixing vars");
            if(this.page && this.page.design && this.page.design.background && !this.page.design.background.overlay) {
                if(this.debug) console.log("fixing ovrly");
                this.page.design.background.overlay = {
                    type: "none",
                    colours: [ { colour: "#000000", position: 0 } ],
                    opacity: 30,
                    show: false
                }
            }
        },
        loadPage: async function() {
            this.working = true;
            if(this.debug) console.log("loading page");
            await this.doLoadPage({})
            .then(page => {
                if(this.debug) console.log("page loaded");
                this.page = page;
                this.fixPageVars();
            })
            .catch(err => {
                console.error(err);
            })
            this.working = false;
        },
        prepPage: function() {
            remove(this.page.design.rows, r => { return !r.elements || !r.elements.length > 0; })
            this.page.design.rows = uniqBy(this.page.design.rows, "id");
        },
        savePage: async function(key) {
            this.working = true;
            this.prepPage();
            await this.doSavePage({id: this.page.id, data: this.page})
            .then(() => {
                if(this.debug) console.log("page saved");
                this.postSave(key);
            })
            .catch(err => {
                console.error(err);
            })
            this.working = false;
        },
        postSave: async function(key) {
            this.working = true;
            
            switch(key) {
                case "handle":
                    this.doUpdatePageHandle({ page: this.page });
                    break;
            }
        },
        fileUploaded: function (payload) {
            if(this.debug) console.log(payload);
            switch(payload.tag) {
                case "profilePic":
                    this.page.profile_pic = payload.newFile;
                    this.page.profile_pic.ref = payload.ref;
                    break;

                case "bannerPic":
                    this.page.design.banner.file = payload.newFile;
                    this.page.design.banner.file.ref = payload.ref;
                    break;

                case "backgroundPic":
                    if(!this.page.design.background) this.page.design.background = { type: "image" };
                    this.page.design.background.type = "image";
                    this.page.design.background.image = payload.newFile;
                    this.page.design.background.ref = payload.ref;
                    break;
            }

            this.savePage();
        },
        handleEditClick: function(payload) {
            if(this.debug) console.log(payload);
            if(!this.page.design.rows) this.page.design.rows = [];
            switch(payload.action) {
                case "add":
                    var el = null;
                    switch(payload.elem) {
                        case "heading":
                            el = {
                                type: "heading",
                                name: "heading",
                                style: {
                                    theme: null,
                                    background: {
                                        color: "transparent",
                                    },
                                    text: {
                                        italic: null,
                                        bold: null,
                                        color: "#000"
                                    },
                                    classes: []
                                },
                                content: {
                                    text: "A Heading"
                                }
                            }
                            break;

                        case "link":
                            el ={
                                type: "link",
                                name: "link",
                                link_display: "button",
                                style: {
                                    theme: null,
                                    background: {
                                        color: "whitesmoke",
                                    },
                                    text: {
                                        italic: null,
                                        bold: null,
                                        color: "#000"
                                    },
                                    border: {
                                        radius: 0,
                                    },
                                    classes: ["is-round"]
                                },
                                content: {
                                    text: "A link",
                                    url: ""
                                },
                            }
                            break;

                        case "socials":
                            el = {
                                type: "socials",
                                name: "Socials",
                                link_display: "button",
                                style: {
                                    theme: null,
                                    background: {
                                        color: "whitesmoke",
                                    },
                                    text: {
                                        italic: null,
                                        bold: null,
                                        color: "#000"
                                    },
                                    border: {
                                        radius: 0,
                                    },
                                    classes: ["is-round"]
                                },
                                content: {
                                    socials: [
                                        {
                                            show: true,
                                            type: "instagram",
                                            icon: { fa: true, type: "fab", name: "instagram-square" },
                                            text: null,
                                            url: "https://instagram.com"
                                        },
                                        {
                                            show: true,
                                            type: "facebook",
                                            icon: { fa: true, type: "fab", name: "facebook-square" },
                                            text: null,
                                            url: "https://facebook.com"
                                        },
                                        {
                                            show: true,
                                            type: "linkedin",
                                            icon: { fa: true, type: "fab", name: "linkedin" },
                                            text: null,
                                            url: "https://linkedin.com"
                                        },
                                        {
                                            show: true,
                                            type: "tiktok",
                                            icon: { fa: true, type: "fab", name: "tiktok" },
                                            text: null,
                                            url: "https://tiktok.com"
                                        },
                                        {
                                            show: true,
                                            type: "twitter",
                                            icon: { fa: true, type: "fab", name: "twitter-square" },
                                            text: null,
                                            url: "https://twitter.com"
                                        },
                                        {
                                            show: true,
                                            type: "pinterest",
                                            icon: { fa: true, type: "fab", name: "pinterest-square" },
                                            text: null,
                                            url: "https://pinterest.com"
                                        },
                                        {
                                            show: true,
                                            type: "reddit",
                                            icon: { fa: true, type: "fab", name: "reddit-square" },
                                            text: null,
                                            url: "https://reddit.com"
                                        },
                                        {
                                            show: true,
                                            type: "spotify",
                                            icon: { fa: true, type: "fab", name: "spotify" },
                                            text: null,
                                            url: "https://open.spotify.com/user/"
                                        },
                                        {
                                            show: true,
                                            type: "website",
                                            icon: { fa: true, type: "fas", name: "globe" },
                                            text: null,
                                            url: ""
                                        }
                                    ]
                                }
                            }
                            break;
                    }

                    el.id = uuidv4();

                    var row = { id: uuidv4(), order: this.page.design.rows.length, elements: [el] };
                    this.page.design.rows.push(row);
                    this.savePage();
                    break;

                case "toggle":
                    switch(payload.elem) {
                        case "banner":
                            this.page.design.banner.show = !this.page.design.banner.show;
                            break;
                    }
                    this.savePage();
                    break;

                case "edit":
                    switch(payload.elem) {
                        case "background":
                            this.triggerModal("bgPicker");
                            //this.$refs.bgUploader.triggerUpload();
                            break;

                        case "profilePic":
                            this.$refs.profilePicUploader.triggerUpload();
                            break;
                    }
                    break;
            } 
        },
        rowsString: function(rows) {
            var ts = [];
            each(orderBy(rows, "order"), row => { each(row.elements, e => { 
                var name = e.content.text || e.type;
                ts.push(row.order + ": " + name + '-' + row.id.substring(0, 6)); 
            }) });
            return ts;
        },
        updateRows: function(rows) {
            //var rows = payload.rows;
            if(this.debug) console.log(rows);
            if(!isArray(rows)) return null;
            this.rowsString(rows);
            this.page.design.rows = rows;
            if(this.debug) console.log("saving...");
            this.savePage();
        
        },
        setEditingFromRows: function(editing) {
            this.editing = editing;
            //this.$refs.rows.setEditing(editing);
        },
        setEditing: function(editing) {
            this.editing = editing;
            this.$refs.rows.setEditing(editing);
        },
        // eslint-disable-next-line no-unused-vars
        elementUpdated: function(payload) {
            if(payload && payload.save && payload.el) {
                switch(payload.el.type) {
                    case "handle":
                        this.page.handle = payload.el.content.text;
                        this.savePage('handle');
                        break;
                }
            }
        },
        resetVal: function(key) {
            switch(key) {
                case "handle":
                    var oldHandle = this.page.handle;
                    this.page.handle = "";
                    this.page.handle = oldHandle;
                    this.update();
                    break;
            }
        },
        triggerBGUpload: function() {
            this.$refs.bgUploader.triggerUpload();
        },
        triggerModal: function(modal) {
            this.modal = modal;
            switch(this.modal) {
                case "bgPicker":
                    this.$refs.bgPicker.show();
                    break;
            }
        },
        dismissModal: function() {
            this.modal = null;
        },
        update: function() {

        }
    },
    mounted: function() {
        window.editor = this;
        this.setup();
    },
    watch: {
        savedPage: {
            immediate: true,
            handler(newVal) {
                //if(!this.page && newVal) this.page = newVal;
                if(this.debug) console.log("watch: savedPage");
                this.page = newVal;
                this.fixPageVars();
            }
        },
        user: {
            handler(newVal) {
                if(newVal && !this.setup_done) this.setup();
            }
        },
        backgroundPic: {
            handler(newVal) {
                if(newVal) this.setBackground({"background.image": newVal})
            }
        }
    }
}

//force upd
</script>

<style lang="scss" scoped>
    .page-outer {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        

        .page-inner {
            display: flex;
            background-color: rgba(black, 0.05);
            flex-direction: column;
            align-items: center;
            padding-top: 5vh;
            overflow-x: hidden;
            min-height: 100vh;
            height: 100%;
            transition: all 0.6s ease;
            border-radius: 0px;
            border: solid 0px transparent;
            margin-top: 0vh;
            box-shadow: 0px 0px 0px 0px transparent;
            width: 100%;
            position: relative;

            .overlay {
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
                position: absolute;
                background-color: transparent;
                z-index: 0;
                height: 100%;
                //border-radius: 20px;
            }

            .banner-pic {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 120px;
                background-color: rgba(black, 0.2);
                z-index: 0;
            }

            .profile-pic {
                min-width: 80px;
                min-height: 80px;
                width: 25vw;
                height: 25vw;
                max-width: 100px;
                max-height: 100px;
                background-color: rgba(black, 0.8);
                border-radius: 50%;
                overflow: hidden;
                margin-bottom: 10px;
                box-shadow: 0px 0px 20px 0px rgba(black, 0.2);
                flex-shrink: 0;
                position: relative;
                z-index: 1;

                &.smaller-profile-pic {
                    background-size: 70%;
                }

                &.no-profile-pic {
                    background-color: rgba($prussianblue, 0.9);
                }
            }

            .handle {
                //margin-top: 20px;
                font-weight: 700;
                color: black;
                font-family: $mainFont;
                position: relative;
                z-index: 10;
                max-width: 80%;
            }

            .rows {
                font-family: $mainFont;
                width: 100%;
                margin-top: 10px;
                padding-bottom: 50px;

                .row {
                    width: 100%;
                    position: relative;
                    display: flex;
                    align-items: center;

                    
                }
            }
            
        }
    }

    .editor {
        background-color: transparent;
        transition: background-color 1s ease;
        position: relative;

        &.preview-mobile {
            background-color: rgba(whitesmoke, 0.8);

            .page-inner {
                background-color: black;
                border: solid 10px black;
                border-radius: 40px;
                margin-top: 10vh;
                box-shadow: 0px 0px 60px rgba(black, 0.4);
                width: 360px;
                height: 90vh;

                
            }
        }

        .working-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(black, 0.5);
            cursor: not-allowed;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;
        }
    }

    
</style>