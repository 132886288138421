<template>
    <div class="gcropper cropper-overlay">
        <div class="cropper-inner round-cropper">
          <img ref="image" :src="bg" />
        </div>
        <button class="button is-small is-rounded is-info" @click="crop()">Crop</button>
        <div v-if="showResult" class="result">
            <img ref="image" :src="roundedImage" />
        </div>
    </div>
</template>

<script>

import Cropper from "cropperjs";
import 'cropperjs/dist/cropper.min.css';
import { mapGetters } from "vuex";

export default {
    name: "gCropper",
    data: function() {
        return {
            cropper: null,
            croppable: null,
            croppedCanvas: null,
            roundedCanvas: null,
            roundedImage: null,
            showResult: false
        }
    },
    computed: {
        ...mapGetters({
            background: "layout/getBackground"
        }),
        bg: function() {
            var img = this.background.default;

            return img;
        },
    },
    methods: {
        getRoundedCanvas: function(sourceCanvas) {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            var width = sourceCanvas.width;
            var height = sourceCanvas.height;

            canvas.width = width;
            canvas.height = height;
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, width, height);
            context.globalCompositeOperation = 'destination-in';
            context.beginPath();
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
            context.fill();
            return canvas;
        },
        crop: function() {

            if (!this.croppable) {
                return;
            }

            // Crop
            this.croppedCanvas = this.cropper.getCroppedCanvas();

            // Round
            this.roundedCanvas = this.getRoundedCanvas(this.croppedCanvas);
            this.roundedImage = this.roundedCanvas.toDataURL();
            this.showResult = true;
        },
        setup: function() {
            this.cropper = new Cropper(this.$refs.image, {
                aspectRatio: 1,
                viewMode: 1,
                ready: () => {
                    this.croppable = true;
                },
                crop(event) {
                    console.log(event.detail.x);
                    console.log(event.detail.y);
                    console.log(event.detail.width);
                    console.log(event.detail.height);
                    console.log(event.detail.rotate);
                    console.log(event.detail.scaleX);
                    console.log(event.detail.scaleY);
                },
            })
        }
    },
    mounted: function() {
        this.setup();
        window.cropper = this;
    }
}
</script>

<style lang="scss" scoped>
    .cropper-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(11,11,11,1);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;

        .cropper-inner {
            /* Ensure the size of the image fit the container perfectly */
            img {
                display: block;

                /* This rule is very important, please don't ignore this */
                max-width: 100%;
            }

        }
    }
</style>