<template>
    <div class="element" :class="[elementClasses, { 'editing': editing, 'fade-out': unfocus, 'solo': solo }]" ref="theElement">
        <!-- <div class="editing-overlay" :class="{'is-hidden': !editing, 'show': editing}">
        </div> -->
        <div v-if="element && element.type === 'handle'" class="element-inner">
            <div 
                v-if="!editing"
                class="handle-content" 
            >
            @{{ element.content.text }}
            </div>
            <div v-else class="editing-box boxed">
                <input 
                    type="text" 
                    class="input is-small handle-content" 
                    v-model="element.content.text" 
                    placeholder="page handle" 
                    @input="checkHandle"
                />
                <article v-if="handleMessage.show" class="message is-small" :class="[handleMessage.type]">
                    <div class="message-body">
                        {{handleMessage.message}}
                    </div>
                </article>
            </div>
        </div>
        <div v-if="element && element.type === 'heading'" class="element-inner">
            <div 
                v-if="!editing"
                class="heading-content" 
                v-html="element.content.text" 
            ></div>
            <div v-else class="editing-box">
                <input type="text" class="input is-small heading-content" v-model="element.content.text" placeholder="link text" />
            </div>
        </div>
        <div v-if="element && element.type === 'link'" class="element-inner">
            <a 
                v-if="!editing"
                class="button is-rounded"
                :href="element.content.url"
                v-html="element.content.text"
            ></a>
            <div v-else class="editing-box boxed">
                <input type="text" class="input is-small" v-model="element.content.text" placeholder="link text" />
                <input type="url" class="input is-small" v-model="element.content.url" placeholder="link url"/>
            </div>
        </div>
        <div v-if="element && element.type === 'socials'" class="element-inner">
            <div v-if="!editing" class="socials-wrapper">
                <a 
                    v-for="(social, socialIndex) in visibleSocials"
                    :key="'social-link-' + socialIndex"
                    class="social-link"
                    :class="[social.type, {'is-hidden': !social.show}]"
                    :href="social.url"
                >
                    <i v-if="social.icon.fa" :class="[social.icon.type, 'fa-' + social.icon.name]" ></i>
                </a>
            </div>
            <div v-else class="editing-box boxed">
                <div 
                    v-for="(social, socialIndex) in element.content.socials"
                    :key="'social-link-editing' + socialIndex"
                    class="editing-box-inner field"
                >
                    <div class="control">
                        <div class="control-inner">
                            <label class="label has-text-left is-small">
                                <i v-if="social.icon.fa" :class="[social.icon.type, social.icon.name]" ></i> {{ social.name || social.type }}
                            </label>
                            <div class="field switch pulled-right">
                                <input :id="'show' + social.type" type="checkbox" :name="'show' + social.type" class="switch is-rounded is-small" v-model="social.show">
                                <label :for="'show' + social.type"></label>
                            </div>
                        </div>
                        <input type="url" class="input is-small" v-model="social.url" placeholder="link url" />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editing" class="toolbar editing-toolbar">
            <div v-if="toolbar.confirming" class="toolbar-inner">
                <div class="question">
                    {{ toolbar.question }}
                </div>
                <button class="toolbar-button is-primary is-small" @click="confirm(true)">
                    <i class="fas fa-check" ></i>
                </button>
                <button class="toolbar-button is-small is-danger" @click="confirm(false)">
                    <i class="fas fa-times" ></i>
                </button>
            </div>
            <div v-else class="toolbar-inner">
                <button 
                    v-if="toolbarButtons.save" 
                    class="toolbar-button is-primary is-small" 
                    :class="{'is-disabled': !canSave}"
                    @click="saveElement"
                    :disabled="!canSave"
                >
                    <i v-if="checking" class="fas fa-spinner fa-spin" ></i>
                    <i v-else class="fas fa-check" ></i>
                </button>
                <button v-if="toolbarButtons.settings" class="toolbar-button is-small is-info is-disabled" :disabled="true" @click="cancelEditing">
                    <i class="fas fa-palette" ></i>
                </button>
                <button v-if="toolbarButtons.del" class="toolbar-button is-small is-danger" @click="askDelete">
                    <i class="fas fa-trash" ></i>
                </button>
                <button v-if="toolbarButtons.cancel" class="toolbar-button is-small is-danger" @click="cancelEditing">
                    <i class="fas fa-times" ></i>
                </button>
            </div>
        </div>
        <div v-else class="toolbar to-edit-toolbar no-bg">
            <button class="toolbar-button is-small is-dark" @click="toggleEditing">
                <i class="fas fa-pen"></i>
            </button>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { each, filter, debounce} from "lodash-es";

export default {
    name: "PageElement",
    data: function() {
        return {
            //element: this.modelValue,
            oldValue: this.modelValue,
            editing: false,
            classes: [],
            toolbar: {
                button_clicked: null,
                context: null,
                confirming: null,
                question: null
            },
            checkingHandle: false,
            handleTaken: true, 
            handleChecked: false
        }
    },
    props: {
        elIndex: Number,
        modelValue: Object,
        editingElement: Boolean,
        solo: Boolean
    },
    computed: {
        element: {
            get() {
                return this.modelValue;
            },
            set(value) {
                console.log({elementVal: value});
                this.$emit("update:modelValue", value);
            }
        },
        unfocus: function() {
            return this.editingElement && !this.editing;
        },
        type: function() {
            if(!this.element) return null;
            return this.element.type;
        },
        elementClasses: function(){
            var ec = [this.type + "-element"];

            switch(this.type) {
                case "heading":
                    ec.push("is-full");
                    break;
                
                case "link":
                    ec.push("is-full");
                    break;

                default:
                    ec.push("is-full");
                    break;
            }

            each(this.classes, c => {
                if(!ec.includes(c)) ec.push(c);
            })

            return ec.join(" ");
        },
        theContent: function() {
            if(!this.element || !this.type) return null;
            switch(this.type) {
                case "heading": 
                    return this.element.content.text;

                default:
                    return this.element.name;
            }
        },
        visibleSocials: function() {
            return filter(this.element.content.socials, s => { return s.show; });
        },
        toolbarButtons: function() {
            var save, settings, del, cancel;

            save = settings = del = cancel = true;

            switch(this.type) {
                case "handle":
                    settings = del = false;
                    break;
            }

            return { save, settings, del, cancel };

        },
        canSave: function() {
            if(this.checking) return false;

            switch(this.type) {
                case "handle":
                    return !this.handleTaken;

                default: 
                    return true;
            }
        },
        checking: function() {
            switch(this.type) {
                case "handle":
                    return this.checkingHandle;
            }

            return false;
        },
        handleMessage: function() {

            var show = this.handleChecked;
            var message = "";
            var type = "is-danger"

            if(!show) return { show }

            if(this.handleTaken) {
                message = "Sorry, this handle is already taken";
            } else {
                type = "is-success";
                message = "Great, this handle is available!";
            }

            return { show, type, message}
        }
    },
    methods: {
        ...mapActions({
            doCheckHandle: "auth/checkHandle",
        }),
        saveElement: function(e) {
            //this.$emit("update:modelValue", this.element);
            this.$emit("save", this.element);
            //this.$emit("save", { save: true, el: this.element});
            this.cancelEditing(e, true);
        },
        toggleEditing: function() {
            this.editing = !this.editing;
            if(this.editing) this.scrollIntoView();
            this.$emit("editing", this.editing);
        },
        scrollIntoView: debounce(function(offset) {
            if(this.$refs.theElement && this.$refs.theElement.scrollIntoView) {
                this.$refs.theElement.scrollIntoView({ behavior: 'smooth' });
                window.scrollBy(0, offset || -20);
            }
        }, 300),
        cancelEditing: function(e, fromSave) {
            this.editing = false;
            this.$emit("editing", this.editing);
            if(!fromSave) {
                switch(this.type) {
                    case "handle": 
                        this.$emit("resetVal");
                        break;
                }
            }
            this.$nextTick(() => {
                this.$forceUpdate();
            })
        },
        checkHandle: debounce(function() {

            if(!this.element.content.text) return;

            this.checkingHandle = true;
            this.doCheckHandle(this.element.content.text)
            .then(checked => {
                this.handleTaken = checked.handle;
                this.checkingHandle = false;
                this.handleChecked = true;
            })
            .catch(err => {
                this.checkingHandle = false;
                console.error(err);
            })
        }, 300),
        askDelete: function() {
            this.toolbar.context = "delete-element";
            this.toolbar.question = "Are you sure?";
            this.toolbar.confirming = true;
        },
        toolbarReset: function() {
            this.toolbar.context = null;
            this.toolbar.question = null;
            this.toolbar.confirming = null;
        },
        confirm: function(yes) {
            if(!yes) this.toolbarReset();
            if(yes) {
                switch(this.toolbar.context) {
                    case "delete-element":
                        this.$emit("deleted", this.element.id);
                        break;

                    default:
                        return null;
                }
            }
        }
    },
//     watch: {
//         modelValue: function(newVal) {
// //            if(newVal) this.element = newVal;
//             if(newVal) {
//                 switch(newVal.type) {
//                     case "handle":
//                         this.element = newVal;
//                         break;
//                 }
//             }
//         }
//     }
}
</script>

<style lang="scss" scoped>
    .element {
        cursor: pointer;
        position: relative;
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .element-inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.is-full {
            width: 100%;
        }

        .toolbar {
            position: absolute;
            left: 0;
            right: 0;
            bottom: calc(-2rem - 15px);
            display: none;
            align-items: center;
            justify-content: center;

            .toolbar-inner {
                background-color: rgba(black, 0.7);
                padding: 6px;
                //padding-bottom: 5px;
                border-radius: 3px;
                align-items: center;
                justify-content: center;
                display: flex;

                .question {
                    padding: 0px 10px;
                    color: white;
                    font-size: 0.9rem;
                    font-weight: bold;
                }
            }

            &.no-bg {
                background-color: transparent;
            }

            .toolbar-button {
                margin-right: 6px;
                padding: 4px;
                font-size: 0.8rem;
                width: 1.5rem;
                height: 1.5rem;
                //line-height: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
                border-radius: 2px;

                @media (hover: none) {
                    width: 2rem;
                    height: 2rem;
                    line-height: 2rem;
                }

                &.is-primary {
                    background-color: $primary;
                    color: white;
                }

                &.is-dark {
                    background-color: $dark;
                    color: white;
                }

                &.is-info {
                    background-color: $info;
                    color: white;
                }

                &.is-danger {
                    background-color: $danger;
                    color: white;
                }

                &:last-child {
                    margin-right: 0px;
                }

                &.is-disabled {
                    color: rgba(white, 0.5);
                    cursor: not-allowed;
                }
            }
        }
        
        &:hover {
            //border: dashed 2px rgba(black, 0.6);
            border-radius: 3px;
        }

        &.editing {
            cursor: normal;
            //background-color: rgba(black, 0.05);

            .toolbar.editing-toolbar {
                display: flex;
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                .toolbar.to-edit-toolbar {
                    right: 5px;
                    display: flex;
                    bottom: unset;
                    left: unset;
                }
            }
        }

        @media (hover: none) {
            .toolbar.to-edit-toolbar {
                right: -30px;
                display: flex;
                bottom: unset;
                left: unset;

                .toolbar-button.is-dark {
                    background-color: rgba($dark, 0.3);
                }
            }
        }

        .editing-box {
            margin: auto;
            border-radius: 3px;
            width: 80%;
            max-width: 80%;
            position: relative;

            &.boxed {
                padding: 10px;
                background-color: whitesmoke;
            }
            
            input.input, input.input:focus {
                border: none;
                background-color: transparent;
                border-bottom: solid 1px rgba(black, 0.2);
                margin-bottom: 10px;
                box-shadow: none;
                outline: none;
                border-radius: 0px;
                text-align: center;

                &:last-child {
                    margin-bottom: 0px;
                }

                &:focus {
                    background-color: rgba(white, 0.05);
                    border-radius: 2px;
                }
            }

            .control-inner {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .field.switch.pulled-right {
                    margin-left: auto;
                }
            }
        }

        &.heading-element {
            // padding: 10px 0px;

            .heading-content {
                text-align: center;
                font-size: 1rem;
                font-weight: bold;
                color: rgba(black, 0.8);
            }
        }

        &.link-element {
            width: 100%;

            .element-inner {
                width: 100%;
            }

            .button {
                min-width: 80%;
                background-color: whitesmoke;
            }

            .editing-box {
                
            }
        }

        &.handle-element {
            //margin-top: 20px;
            font-weight: 700;
            color: black;
            font-family: $mainFont;
            position: relative;
            z-index: 10;

            .editing-box {
                input.input {
                    font-weight: bold;
                    font-size: 1rem;
                    color: black;
                }
            }
        }

        &.socials-element {

            .socials-wrapper {

                display: flex;
                align-items: center;

                a.social-link {
                    color: $black;
                    margin-right: 7px;
                    font-size: 1.8rem;

                    &:last-child {
                        margin-right: 0px;
                    }
                }
            }

            .editing-box {
                .editing-box-inner {

                }
            }
        }

        &.fade-out {
            z-index: 0;
            opacity: 0.3;
            
            &.solo {
                opacity: 0.3;
                cursor: not-allowed;
                filter: blur(3px);
            }

            &:hover {
                .toolbar {
                    display: none;
                }
            }
        }

        .editing-overlay {
            position: fixed;
            z-index: 0;
            background-color: rgba(black, 0.3);
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            display: none;
            opacity: 0;
            transition: opacity 0.3s ease;

            &.show {
                display: inherit;
                opacity: 1;
            }
        }
        
    }
</style>