<template>
    <!-- <div > -->
        <draggable 
            :list="rows" 
            group="rows" 
            @end="rowOrderChanged"
            item-key="id"
            id="rows" class="rows" ref="rows"
            handle=".handle"
        >
            <template #item="{element, index}">
                <Row 
                    :data-id="element.id"
                    :title="element.id"
                    :data-index="index"
                    class="row" 
                    :modelValue="element" 
                    :rowEditing="editing"
                    @update:modelValue="rowUpdated" 
                    @editing="setEditing"
                    @deleted="rowDeleted"
                />
            </template>
        </draggable>
    <!-- </div> -->
</template>

<script>
import Row from "@/components/Row.vue";
// import Sortable from 'sortablejs';
import { each, findIndex, remove, orderBy } from "lodash-es";
import draggable from 'vuedraggable'

export default {
    name: "Rows",
    data: function() {
        return {
            sortable: null,
            //rows: this.modelValue,
            sortableOptions: null,
            editing: false,
            drag: false,
            draggable,
            debug: false
        }
    },
    props: {
        modelValue: Object,
    },
    components: {
        Row,
        draggable
    },
    computed: {
        rows: {
            get() {
                if(this.debug) console.log("rows: getting rows");
                if(this.debug) console.log(this.rowsString(this.modelValue));
                return this.modelValue;
            },
            set(value) {
                if(this.debug) console.log("rows: setting rows");
                if(this.debug) console.log(this.rowsString(this.value));
                this.$emit('update:modelValue', value);
            }
        },
        toString: function() {
            var ts = [];
            each(this.rows, row => { 
                var name = row.elements[0].content.text || row.elements[0].type;
                ts.push(row.order + ': ' + name); 
            });
            return ts;
        }
    },
    methods: {
        rowsString: function() {
            var ts = [];
            each(orderBy(this.rows, "order"), row => { each(row.elements, e => { 
                var name = e.content.text || e.type;
                ts.push(row.order + ": " + name + '-' + row.id.substring(0, 6)); 
            }) });
            return ts;
        },
        sortOrder: function() {
            if(!this.sortable) return null;
            var newOrder = this.sortable.toArray();
            //each(newOrder, (n, i) => { newOrder[i] = parseInt(n); });
            return newOrder;
        },
        newRows: function() {
            var sortOrder = this.$refs.rows.list;
            if(!sortOrder) return this.rows;
            if(this.debug) console.log("sortOrder", sortOrder);
            var nr = this.rows;
            each(sortOrder, (so, i) => {
                var r = findIndex(nr, r => { return r.id === so.id; })
                if(r > -1) {
                    nr[r].order = i;
                    var row = nr[r];
                    var name = row.elements[0].content.text || row.elements[0].type;
                    if(this.debug) console.log(i, name, row.id.substring(0, 6));
                } 
            })
            this.rows = nr;
            //this.$emit("update:modelValue", this.rows);
        },
        rowUpdated: function() {
            if(this.debug) console.log("rowUpdated");
            this.newRows();
            this.save();
        },
        rowDeleted: function(rowId) {
            if(this.debug || true) console.log("deleting row", rowId);
            var rows = this.rows;
            remove(rows, r => { return r.id === rowId });
            console.log(this.rowsString(rows));
            console.log(rows);
            this.rows = rows;
            this.newRows();
            this.setEditing(false);
            this.$nextTick(() => {
                this.save();
            })
            
        },
        rowOrderChanged: function() {
            if(this.debug) console.log("rowOrderChanged");
            // return null;
            // eslint-disable-next-line no-unreachable
            this.newRows();
            this.save();
        },
        save: function() {
            this.$emit("save");
        },
        // doSortable: function() {
        //     if(this.$refs && this.$refs.rows) {
        //         console.log("did sortable");
        //         if(!this.sortable) this.sortable = Sortable.create(this.$refs.rows, this.sortableOptions);
        //     }
        // },
        setEditing: function(editing) {
            this.editing = editing;
            this.$emit("editing", editing)
        },
        donothing: function() {
            //console.log("doing nothing", e);
        }
    },
    mounted: function() {
        // this.sortableOptions = {
        //     handle: '.handle',
        //     //sort: true,
        //     animation: 200,
        //     ghostClass: 'ghost-row',
        //     dragClass: 'drag-row',
        //     chosenClass: 'chosen-row',
        //     draggable: ".row",
        //     onUpdate: this.rowOrderChanged,
        //     scroll: true,
        //     supportPointer: false,
        //     //delay: 250, // time in milliseconds to define when the sorting should start
        //     //delayOnTouchOnly: true,
        //     direction: "vertical",
        //     //forceFallback: true
        // }

        //this.doSortable();

        window.rows = this;
    },
    // watch: {
    //     modelValue: {
    //         deep: true,
    //         handler: function(newVal) {
    //             console.log({newVal});
    //             if(newVal) {
    //                 this.rows = newVal;
    //                 //this.doSortable();
    //             }
    //         }
    //     }
    // }
}
</script>

<style lang="scss" scoped>
    $darkColor: rgb(48, 48, 48);
    $lightColor: rgba($darkColor, 0.5);

    .rows {
        font-family: $mainFont;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10vh;
        position: relative;

        .row {
            &.ghost-row {
                background-color: rgba($lightColor, 0.2);
                //opacity: 0;
            }

            &.drag-row {
                //background-color: rgb(48, 48, 48);
                //box-shadow: 0px 0px 10px 0px rgba(white, 0.7);
                //box-shadow: inset 0px 0px 0px 3px $darkColor;
                
            }


            &.chosen-row {
                //background-color: rgb(48, 48, 48);
                //box-shadow: 0px 0px 10px 0px rgba(white, 0.7);
                box-shadow: inset 0px 0px 0px 3px rgba($lightColor, 0.4);
                
            }
        }
    }
</style>